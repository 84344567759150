import { useQuery } from '@tanstack/react-query'
import { getCanvases } from '../../../api/hooks/canvases/canvasApi'
import { getWorkspaceID } from '../../../utils'
import React, { useEffect, useRef, useState } from 'react'
import { setCanvasPolling } from '../../../store/actions/uiActions'
import { useDispatch, useSelector } from 'react-redux'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'

const MAX_POLLING_COUNT = 30

export const CanvasPolling = () => {
    const workspaceID = getWorkspaceID()
    const dispatch = useDispatch()
    const isPolling = useSelector((state) => state.ui.isCanvasPolling)

    const [count, setCount] = useState(0)

    const { data, isFetching } = useQuery({
        queryKey: ['canvases', workspaceID],
        queryFn: async () => {
            const response = await getCanvases()
            return Array.isArray(response) ? response : []
        },
        enabled: !!workspaceID && count <= MAX_POLLING_COUNT && isPolling,
        refetchInterval: isPolling ? 10000 : false,
    })

    const prevDataRef = useRef(null)
    const [snackbar, setSnackbar] = useState(null)

    useEffect(() => {
        if (isPolling) {
            setSnackbar({
                msg: 'We are generating your canvas. This can take several minutes.',
                severity: 'info',
            })
            setCount(0)
        }
    }, [isPolling])

    useEffect(() => {
        if (!isFetching) {
            if (count >= MAX_POLLING_COUNT) {
                dispatch(setCanvasPolling(false))
                setSnackbar({
                    msg: 'Failed to create canvas. Please try again.',
                    severity: 'error',
                })
                setCount(0)
            }
            setCount((prev) => prev + 1)
        }
    }, [isFetching])

    useEffect(() => {
        if (!data || !isPolling) return
        if (count > 0 && prevDataRef.current && prevDataRef.current.length < data.length) {
            setSnackbar({
                msg: 'Canvas created successfully!',
                severity: 'success',
            })
            setTimeout(() => {
                dispatch(setCanvasPolling(false))
            }, 300)

            setCount(0)
        }

        prevDataRef.current = data
    }, [data])

    return (
        <Snackbar
            open={!!snackbar}
            autoHideDuration={10000}
            onClose={() => setSnackbar(null)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
            <Alert onClose={() => setSnackbar(null)} severity={snackbar?.severity} variant="filled">
                {snackbar?.msg}
            </Alert>
        </Snackbar>
    )
}
