import Dialog from '../../../common/Dialog/Dialog'
import { Box, Grid, MenuItem, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import Button from '../../../common/Button/Button'
import { ScoopLoader } from '../../../common/Spinner/ScoopLoader'
import { Server } from '../../../../api/Server'
import { useDispatch, useSelector } from 'react-redux'
import { APIConnector } from '../../Appconnect/API/APIConnector'
import { useNavigate } from 'react-router-dom'
import SearchBar from '../../../common/SeacrhBar/SearchBar'
import { debounce, toLower } from 'lodash'
import Selector from '../../../common/Selector/Selector'
import { ScoopTheme } from '../../../Insights/Style'
import { logEvent } from '../../../../api/hooks/logs/api'
import { setOnboardingChecklist, setShowRecipeModal } from '../../../../store/actions/uiActions'
import userflow from 'userflow.js'
import { useCanvases } from '../../../../api/hooks/canvases/useCanvases'

export const NewFromRecipe = ({ isOpen, setOpen }) => {
    const userID = useSelector((state) => state.auth.userID)
    const workspaceID = useSelector((state) => state.auth.workspaceID)
    const token = useSelector((state) => state.auth.token)
    const openByOnboarding = useSelector((state) => state.ui.showRecipeModal)

    const [server, setServer] = React.useState(new Server(workspaceID, userID, token))
    const [recipeMaps, setRecipeMaps] = useState(null)
    // This is initialized with the same value as recipeMaps and never mutated
    const [recipeMapsCopy, setRecipeMapsCopy] = useState(null)
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [selectedConnector, setSelectedConnector] = useState(null)
    const [step, setStep] = useState(0)
    const [selectedMap, setSelectedMap] = React.useState(null)
    const [connectionKey, setConnectionKey] = React.useState(null)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [canvasList, setCanvasList] = React.useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const debounceFn = debounce((value) => {
        // if length of value is over 5 chars, log event
        if (value.length > 5) {
            logEvent('newCanvasFromRecipe', {
                type: 'attemptSearchRecipe',
                recipe: {
                    name: value,
                },
            })
        }
        recipeMaps &&
            setRecipeMaps(
                recipeMapsCopy.filter((recipeMap) =>
                    recipeMap.recipeMapName.toLowerCase().includes(toLower(value))
                )
            )
    }, 200)
    const onboardingChecklist = useSelector((state) => state.ui.onboardingChecklist)
    const { refetch } = useCanvases()

    const categories = useMemo(() => {
        let categories = []
        categories.push('All')
        recipeMapsCopy &&
            recipeMapsCopy.forEach((recipeMap) => {
                if (Array.isArray(recipeMap.recipeCategory)) {
                    recipeMap.recipeCategory.forEach((category) => {
                        if (!categories.includes(category)) {
                            categories.push(category)
                        }
                    })
                } else {
                    if (!categories.includes(recipeMap.recipeCategory)) {
                        categories.push(recipeMap.recipeCategory)
                    }
                }
            })
        setSelectedCategory(categories[0])
        return categories
    }, [recipeMapsCopy])

    const connectors = useMemo(() => {
        let connectors = []
        connectors.push('All')
        recipeMapsCopy &&
            recipeMapsCopy.forEach((recipeMap) => {
                recipeMap.connectors.forEach((connector) => {
                    if (!connectors.includes(connector)) {
                        connectors.push(connector)
                    }
                })
            })
        setSelectedConnector(connectors[0])
        return connectors
    }, [recipeMapsCopy])

    useEffect(() => {
        if (userID && token && workspaceID) setServer(new Server(workspaceID, userID, token))
    }, [userID, token, workspaceID])

    useEffect(() => {
        const recipeMapping = localStorage.getItem('recipeMapping')
        if (recipeMapping && recipeMapping !== 'ALL' && recipeMapsCopy) {
            const recipe = recipeMapsCopy.find((recipe) => recipe.mappingID === recipeMapping)
            setSelectedMap(recipe)
            recipe ? setStep(1) : setStep(0)
        }
    }, [recipeMapsCopy])

    useEffect(() => {
        const recipeMapping = localStorage.getItem('recipeMapping')
        if (recipeMapping && recipeMapping !== 'ALL') setStep(1)
        if (server?.token) {
            setIsLoading(true)
            server.postData(
                {
                    action: 'getRecipeMappings',
                },
                (results) => {
                    if (results.recipes) {
                        setRecipeMaps(results.recipes)
                        setRecipeMapsCopy(results.recipes)
                        setIsLoading(false)
                    }
                },
                undefined,
                () => setIsLoading(false)
            )
        }
    }, [])

    const handleSearchChange = (event) => {
        debounceFn(event.target.value)
    }

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value)
        if (event.target.value === 'All') {
            setRecipeMaps([...recipeMapsCopy])
        } else {
            const newRecipeMaps = recipeMapsCopy.filter((recipeMap) => {
                if (Array.isArray(recipeMap.recipeCategory)) {
                    return recipeMap.recipeCategory.includes(event.target.value)
                } else {
                    return recipeMap.recipeCategory === event.target.value
                }
            })
            setRecipeMaps(newRecipeMaps)
        }
    }

    const handleConnectorChange = (event) => {
        setSelectedConnector(event.target.value)
        if (event.target.value === 'All') {
            setRecipeMaps([...recipeMapsCopy])
        } else {
            const newRecipeMaps = recipeMapsCopy.filter((recipeMap) => {
                return recipeMap.connectors.includes(event.target.value)
            })
            setRecipeMaps(newRecipeMaps)
        }
    }

    const getColorForCategory = (category) => {
        const index = categories.indexOf(category)
        return ScoopTheme.color[index - 1]
    }

    const renderCategoryPill = (category) => {
        if (Array.isArray(category)) {
            return category.map((cat, index) => {
                return (
                    <Box
                        key={index}
                        sx={{
                            backgroundColor: getColorForCategory(cat),
                            color: 'white',
                            borderRadius: '16px',
                            padding: '2px 6px',
                            fontSize: '10px',
                            display: 'inline-block',
                            whiteSpace: 'nowrap',
                            margin: '0 8px 0 0',
                        }}
                    >
                        {cat}
                    </Box>
                )
            })
        } else {
            return (
                <Box
                    sx={{
                        backgroundColor: getColorForCategory(category),
                        color: 'white',
                        borderRadius: '16px',
                        padding: '2px 6px',
                        fontSize: '10px',
                        width: 'auto',
                        display: 'inline-block',
                        whiteSpace: 'nowrap',
                        margin: '0 8px 0 0',
                        overflow: 'hidden',
                    }}
                >
                    {category}
                </Box>
            )
        }
    }

    const RecipeCard = ({ recipeMap, clickable = true }) => {
        return (
            <Grid item key={recipeMap.recipeMapName} xs={6} padding={1}>
                <Box
                    className={`
                ${recipeMap.recipeMapName === selectedMap?.recipeMapName && 'selected-box'} 
                    source-box ${!clickable && 'remove-hover'}`}
                    onClick={() => {
                        if (!clickable) return
                        setSelectedMap(recipeMap)
                        localStorage.removeItem('recipeMapping')
                    }}
                    sx={{ display: 'flex', flexDirection: 'column' }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            alignItems: 'center',
                        }}
                    >
                        <img src={recipeMap.logoURL} style={{ width: '24px', height: '24px' }} />
                        <Typography sx={{ fontWeight: 'bold', width: '100%', ml: 1 }}>
                            {recipeMap.recipeMapName}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            alignItems: 'center',
                        }}
                    >
                        <Box sx={{ maxWidth: '50%', overflow: 'hidden' }}>
                            <Typography sx={{ fontSize: 14 }}>
                                {recipeMap.descriptionHTML}
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'start',
                                    width: '100%',
                                    overflow: 'hidden',
                                    marginTop: '8px',
                                }}
                            >
                                {renderCategoryPill(recipeMap.recipeCategory)}
                            </Box>
                        </Box>
                        <Box>
                            <img
                                src={recipeMap.screenshotURL}
                                style={{ width: '270px', height: '150px' }}
                                alt={'image'}
                            />
                        </Box>
                    </Box>
                </Box>
            </Grid>
        )
    }

    const renderSelectMapping = () => {
        return (
            <Dialog
                style={{ width: '1200px', height: '720px' }}
                open={isOpen}
                maxWidth={'lg'}
                onClose={() => handleClose()}
                actions={
                    <>
                        <Button className={'button-grey small'} onClick={onClose}>
                            Cancel
                        </Button>
                        {selectedMap && selectedMap.mappingID && step === 0 && (
                            <Button
                                className={'button-purple small'}
                                onClick={() => {
                                    logEvent('newCanvasFromRecipe', {
                                        type: 'attemptNewCanvasFromRecipe',
                                        recipe: {
                                            name: selectedMap.recipeMapName,
                                        },
                                    })
                                    setStep(1)
                                }}
                            >
                                Next
                            </Button>
                        )}
                    </>
                }
                containerStyle={{ height: '100%' }}
                contentStyle={{ height: 'calc(100% - 34px)' }}
                formControlStyles={{ height: '100%' }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '0px',
                    }}
                >
                    <Typography
                        sx={{
                            color: '#201024',
                            fontSize: '24px',
                            fontWeight: 600,
                        }}
                    >
                        Create New Canvas from Recipe
                    </Typography>
                    <Typography
                        sx={{
                            display: 'inline',
                            textAlign: 'center',
                            width: '75%',
                            color: '#635566',
                        }}
                    >
                        Select from a list of pre-defined recipes
                    </Typography>
                </Box>
                <Box
                    sx={{
                        margin: '0 8px',
                        display: 'flex',
                        alignItems: 'flex-end',
                        justifyContent: 'space-between',
                    }}
                >
                    {categories && categories.length > 0 && (
                        <Box>
                            <Selector
                                value={selectedCategory}
                                onChange={handleCategoryChange}
                                label={'Filter by category'}
                                labelClassName={'selector-label-bold'}
                                sx={{
                                    height: 38,
                                    fontFamily: 'Inter, sans-serif',
                                    fontSize: '14px',
                                    width: '300px',
                                }}
                            >
                                {categories.map((category) => {
                                    return (
                                        <MenuItem key={category} value={category}>
                                            {category}
                                        </MenuItem>
                                    )
                                })}
                            </Selector>
                        </Box>
                    )}
                    {connectors && connectors.length > 0 && (
                        <Box>
                            <Selector
                                value={selectedConnector}
                                onChange={handleConnectorChange}
                                label={'Filter by application'}
                                labelClassName={'selector-label-bold'}
                                sx={{
                                    height: 38,
                                    fontFamily: 'Inter, sans-serif',
                                    fontSize: '14px',
                                    width: '300px',
                                }}
                            >
                                {connectors.map((connector) => {
                                    return (
                                        <MenuItem key={connector} value={connector}>
                                            {connector}
                                        </MenuItem>
                                    )
                                })}
                            </Selector>
                        </Box>
                    )}
                    <SearchBar placeholder={'Search Recipes'} onChange={handleSearchChange} />
                </Box>
                {isLoading ? (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '450px',
                        }}
                    >
                        <ScoopLoader size={72} sx={{ margin: 'auto' }} />
                    </Box>
                ) : (
                    <Grid sx={{ overflow: 'auto', height: 'calc(100% - 150px)' }} container>
                        {recipeMaps && recipeMaps.length > 0 ? (
                            recipeMaps.map((recipeMap, index) => {
                                return (
                                    <RecipeCard key={index} recipeMap={recipeMap} index={index} />
                                )
                            })
                        ) : (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100%',
                                    width: '100%',
                                }}
                            >
                                <Typography sx={{ color: '#635566', fontSize: '18px' }}>
                                    No recipes found
                                </Typography>
                            </Box>
                        )}
                    </Grid>
                )}
            </Dialog>
        )
    }

    const onClose = () => {
        openByOnboarding ? dispatch(setShowRecipeModal(false)) : setOpen(false)
        if (localStorage.getItem('recipeMapping')) {
            localStorage.removeItem('recipeMapping')
        }
    }

    const handleClose = () => {
        openByOnboarding ? dispatch(setShowRecipeModal(false)) : setOpen(false)
        setStep(0)
        localStorage.removeItem('recipeMapping')
    }

    const getCanvasList = (server, next) => {
        server.postData(
            {
                action: 'getObjects',
                class: 'scoop.canvas.Canvas',
                attributeName: 'workspaceID',
                attributeValue: workspaceID,
                noCache: true,
            },
            (results) => {
                if (next) next(results.objects)
            }
        )
    }

    const testDone = (server, interval, previousCanvases) => {
        if (server?.token) {
            server.postData(
                {
                    action: 'getProcessStatus',
                    workspaceID: workspaceID,
                },
                (results) => {
                    if (results.inboxStatus) {
                        if (
                            !results.inboxStatus.some(
                                (s) => s.type === 'instantiateRecipe' || s.type === 'extract'
                            )
                        ) {
                            getCanvasList(server, (newCanvasList) => {
                                if (newCanvasList.length > previousCanvases.length) {
                                    setStep(0)
                                    const newCanvas = newCanvasList.reduce((maxCanvas, canvas) => {
                                        return Number(canvas.canvasID.slice(1)) >
                                            Number(maxCanvas?.canvasID.slice(1) || -Infinity)
                                            ? canvas
                                            : maxCanvas
                                    }, null)
                                    clearInterval(interval)
                                    {
                                        if (localStorage.getItem('recipeMapping')) {
                                            logEvent('recipeProductInvocation', {
                                                type: 'attemptProductFromRecipe',
                                                recipe: {
                                                    name: selectedMap.recipeMapName,
                                                },
                                            })
                                        } else {
                                            logEvent('newCanvasFromRecipe', {
                                                type: 'completeNewCanvasFromRecipe',
                                                canvas: {
                                                    canvasID: newCanvas.canvasID,
                                                },
                                                recipe: {
                                                    name: selectedMap.recipeMapName,
                                                },
                                            })

                                            userflow.track('new_canvas', {
                                                type: 'recipe',
                                                recipe: selectedMap.recipeMapName,
                                                name: newCanvas.canvasName,
                                            })
                                        }
                                    }
                                    onClose()
                                    if (onboardingChecklist === '2')
                                        try {
                                            userflow.track('created_canvas_from_recipe', {
                                                selectedConnector: selectedConnector,
                                            })
                                            console.log(
                                                'sent userflow event',
                                                'created_canvas_from_recipe',
                                                {
                                                    selectedConnector: selectedConnector,
                                                }
                                            )
                                        } catch (error) {}
                                    // GJ Jan 23: Commented out because UserFlow is supposed to take over the onboarding checklist
                                    // dispatch(setOnboardingChecklist('3'))
                                    if (newCanvasList.length > 1) {
                                        newCanvasList.forEach((newCanvas) => {
                                            if (
                                                !previousCanvases.some(
                                                    (canvas) =>
                                                        canvas.canvasID === newCanvas.canvasID
                                                )
                                            ) {
                                                dispatch({
                                                    type: 'UPDATE_CANVAS',
                                                    canvasID: '',
                                                    canvasName: '',
                                                })
                                                dispatch({
                                                    type: 'APPLY_LOADED_OBJECTS_STATE',
                                                    payload: [],
                                                })
                                                navigate(`${newCanvas.canvasID}`, {
                                                    state: 'newCanvas',
                                                })
                                            }
                                        })
                                    } else {
                                        dispatch({
                                            type: 'UPDATE_CANVAS',
                                            canvasID: '',
                                            canvasName: '',
                                        })
                                        dispatch({
                                            type: 'APPLY_LOADED_OBJECTS_STATE',
                                            payload: [],
                                        })
                                        navigate(`${newCanvasList[0].canvasID}`, {
                                            state: 'newCanvas',
                                        })
                                    }
                                    refetch()
                                }
                            })
                        }
                    }
                }
            )
        }
    }

    const handleConfirm = () => {
        if (server?.token) {
            server.sheetPostData(
                {
                    action: 'instantiateRecipe',
                    recipeMap: selectedMap,
                    connectionKey: connectionKey,
                },
                (results) => {
                    if (results.status && results.status === 'instantiatingRecipe') {
                        setStep(3)
                        getCanvasList(server, (list) => {
                            const interval = setInterval(() => {
                                if (isOpen) {
                                    testDone(server, interval, list)
                                } else {
                                    clearInterval(interval)
                                }
                            }, 3000)
                        })
                    }
                }
            )
        }
    }

    switch (step) {
        case 0:
            return renderSelectMapping()
        case 1:
            return (
                <APIConnector
                    open={step === 1}
                    onClose={onClose}
                    stepBack={() => setStep(0)}
                    stepConnected={() => {
                        setStep(2)
                    }}
                    connectorType={selectedMap?.connectors[0]}
                    handleConnectOnly={true}
                    setKey={setConnectionKey}
                    RecipeCard={<RecipeCard recipeMap={selectedMap} clickable={false} />}
                    loading={isLoading || !selectedMap}
                />
            )
        case 2:
            return (
                <Dialog
                    open={step === 2}
                    onClose={handleClose}
                    actions={[
                        <Button key={1} className={'small'} onClick={handleClose}>
                            Cancel
                        </Button>,
                        <Button key={2} className={'button-purple small'} onClick={handleConfirm}>
                            Confirm
                        </Button>,
                    ]}
                    title={'Confirm'}
                >
                    <Typography className={'inter'}>
                        Confirm that you would like to create a new Canvas based on the following
                        recipe and data source
                    </Typography>
                    <Grid
                        container={true}
                        spacing={0}
                        sx={{
                            width: '100%',
                            font: 'Integer',
                            fontWeight: '400',
                        }}
                    >
                        <Grid item xs={4} sx={{ fontWeight: '600' }}>
                            Recipe:
                        </Grid>
                        <Grid item xs={8}>
                            {selectedMap.recipeMapName}
                        </Grid>
                        <Grid item xs={4} sx={{ fontWeight: '600' }}>
                            Data Source:
                        </Grid>
                        <Grid item xs={8}>
                            {selectedMap.connectorType}
                        </Grid>
                    </Grid>
                </Dialog>
            )
        case 3:
            return (
                <Dialog
                    open={step === 3}
                    actions={[]}
                    title={'Setting Up Recipe'}
                    showCloseButton={false}
                >
                    <Box
                        sx={{
                            width: '500px',
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            gap: '16px',
                        }}
                    >
                        <Typography className={'inter'} sx={{ mt: 3, mb: 2 }}>
                            Setting up your Scoop recipe
                        </Typography>
                        <ScoopLoader size={72} sx={{ mb: 2 }}></ScoopLoader>
                    </Box>
                </Dialog>
            )
    }
    return null
}
