import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { getInsights, deleteInsightRequest, createInsightRequest } from './insightsApi'
import { getWorkspaceID } from '../../../utils'

export const useInsights = () => {
    const queryClient = useQueryClient()
    const workspaceID = getWorkspaceID()

    const { data, isLoading, refetch } = useQuery({
        queryKey: ['insights', workspaceID],
        queryFn: () => getInsights(workspaceID),
        enabled: !!workspaceID,
    })

    const { mutate: createInsight, isPending: isCreating } = useMutation({
        mutationFn: ({ insightName, savedInsight, workspaceID, path }) =>
            createInsightRequest({ insightName, savedInsight, workspaceID, path }),
        onSuccess: (response, variables) => {
            variables.onSuccess(response.insightKey)
        },
    })

    const { mutate: deleteInsight, isPending: isDeleting } = useMutation({
        mutationFn: ({ insightKey, onSuccessCallback }) => deleteInsightRequest({ insightKey }),
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries(['insights', workspaceID])
            if (variables?.onSuccessCallback) {
                variables.onSuccessCallback()
            }
        },
    })

    return {
        insights: data?.objects || [],
        isLoading,
        refetch,
        deleteInsight,
        isDeleting,
        createInsight,
    }
}
