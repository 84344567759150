import React, { useEffect, useState } from 'react'
import './ScoopFileDirectory.css'
import { Box, IconButton } from '@mui/material'
import Button from '../Button/Button'
import Folder from '../../../assets/icons/Folder.svg'
import FolderPlus from '../../../assets/icons/FolderPlus.svg'
import SquaresFour from '../../../assets/icons/SquaresFour.svg'
import ListBullets from '../../../assets/icons/ListBullets.svg'
import ArrowLeft from '../../../assets/icons/ArrowLeft.svg'
import File from '../../../assets/icons/File.svg'
import Typography from '@mui/material/Typography'
import SearchBar from '../SeacrhBar/SearchBar'
import Dialog from '../Dialog/Dialog'
import Input from '../Input/Input'

export const ScoopFileDirectory = ({ files, selectedFile, setSelectedFile, setPath, isSaving }) => {
    const [currentPath, setCurrentPath] = useState('/')
    const [fileView, setFileView] = useState('list')
    const [searchQuery, setSearchQuery] = useState('')
    const [openModal, setOpenModal] = useState(false)
    const [newFolderName, setNewFolderName] = useState('')
    const pathLevel = currentPath === '/' ? 0 : currentPath.split('/').length

    useEffect(() => {
        if (setPath) setPath(currentPath)
    }, [currentPath])

    const selectedFileName = files.find((f) => f.id === selectedFile)?.name

    const getCurrentPathElements = () => {
        let pathElements = files.filter((f) => f.path.startsWith(currentPath))
        if (searchQuery.trim() !== '') {
            pathElements = pathElements.filter((f) =>
                f.name?.toLowerCase().includes(searchQuery.toLowerCase())
            )
        }
        const pathFiles = pathElements.filter((f) => f.path === currentPath)
        const pathFolders = pathElements
            .filter((f) => f.path.startsWith(currentPath) && f.path.length > currentPath.length)
            .map((f) => f.path.split('/')[currentPath === '/' ? 1 : pathLevel])
            .filter((f, i, a) => a.indexOf(f) === i)
        return [...pathFolders, ...pathFiles]
    }

    const handleFolderSelection = (folderName) => {
        setCurrentPath((state) => state + (state === '/' ? folderName : '/' + folderName))
        setSelectedFile('')
    }

    const renderCurrentPathElements = () => {
        const elements = getCurrentPathElements()
        return elements.map((element) => {
            if (typeof element === 'string') {
                return (
                    <Box
                        key={element.id}
                        className={
                            'folder-element ' +
                            (fileView === 'list' ? 'list-folder' : 'squares-folder')
                        }
                        onDoubleClick={() => handleFolderSelection(element)}
                    >
                        <img src={Folder} alt={'folder'} />
                        <Typography className={'inter'}>{element}</Typography>
                    </Box>
                )
            } else {
                if (!isSaving) {
                    return (
                        <Box
                            key={element.id}
                            className={
                                'file-element ' +
                                (selectedFile === element.id ? 'file-element-selected ' : '') +
                                (fileView === 'list' ? 'list-file' : 'squares-file')
                            }
                            onClick={() => setSelectedFile(element.id)}
                        >
                            <img src={File} alt={'file'} />
                            <Typography className={'inter'}>{element.name}</Typography>
                        </Box>
                    )
                } else return null
            }
        })
    }

    const handleBack = () => {
        const newPath = currentPath.split('/')
        newPath.splice(-1)
        setCurrentPath(newPath.length === 1 ? '/' : newPath.join('/'))
        setSelectedFile(null)
    }

    const handleCreateFolder = () => {
        if (newFolderName.trim() === '') return
        const newFolderPath = currentPath + (currentPath === '/' ? '' : '/') + newFolderName
        setCurrentPath(newFolderPath)
        setOpenModal(false)
        setNewFolderName('')
    }

    return (
        <Box className={'file-directory-container'}>
            <Box className={'file-directory-content'}>
                <Box className={'file-directory-header'}>
                    <Box className={'file-directory-search'}>
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '8px',
                                width: '100%',
                                alignItems: 'center',
                            }}
                        >
                            <IconButton
                                className={'files-view-mode'}
                                disabled={currentPath === '/'}
                                onClick={handleBack}
                            >
                                <img src={ArrowLeft} alt={'squares'} style={{ height: 20 }} />
                            </IconButton>
                            <Input
                                disableMargin
                                placeholder={'Search files'}
                                textFieldSx={{
                                    height: 30,
                                    '& .MuiInputBase-input': {
                                        padding: '2px',
                                    },
                                }}
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </Box>
                        <Box style={{ display: 'flex', gap: '8px' }}>
                            {isSaving && (
                                <IconButton
                                    onClick={() => setOpenModal(true)}
                                    className={'files-view-mode'}
                                >
                                    <img
                                        src={FolderPlus}
                                        alt={'Folder Plus'}
                                        style={{ height: 20 }}
                                    />
                                </IconButton>
                            )}
                            <IconButton
                                onClick={() => setFileView('squares')}
                                className={
                                    'files-view-mode ' +
                                    (fileView === 'squares' ? 'files-view-mode-selected' : '')
                                }
                            >
                                <img src={SquaresFour} alt={'squares'} style={{ height: 20 }} />
                            </IconButton>
                            <IconButton
                                onClick={() => setFileView('list')}
                                className={
                                    'files-view-mode ' +
                                    (fileView === 'list' ? 'files-view-mode-selected' : '')
                                }
                            >
                                <img src={ListBullets} alt={'list'} style={{ height: 20 }} />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box sx={{ padding: '0 8px' }}>
                        <Typography
                            className={'inter'}
                            style={{ fontWeight: 400, fontSize: '12px', marginTop: '8px' }}
                        >
                            {currentPath}
                            {selectedFileName ? '/' + selectedFileName : ''}
                        </Typography>
                    </Box>
                </Box>
                <Box
                    className={
                        'file-directory-files ' +
                        (fileView === 'list' ? 'list-view' : 'squares-view')
                    }
                >
                    {renderCurrentPathElements()}
                </Box>
            </Box>
            <Dialog
                open={openModal}
                onClose={() => setOpenModal(false)}
                actions={
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            gap: '8px',
                            marginTop: '16px',
                        }}
                    >
                        <Button
                            className={'primary-button button-grey small'}
                            onClick={() => setOpenModal(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            className={'primary-button button-purple small'}
                            onClick={handleCreateFolder}
                        >
                            Create
                        </Button>
                    </Box>
                }
                title={'Create New Folder'}
            >
                <Input
                    fullWidth
                    label="Folder Name"
                    value={newFolderName}
                    onChange={(e) => setNewFolderName(e.target.value)}
                />
            </Dialog>
        </Box>
    )
}
