import React, { useEffect, useState } from 'react'
import {
    IconButton,
    ListItem,
    ListItemText,
    Tooltip,
    Chip,
    Typography,
    Box,
    Icon,
    CircularProgress,
} from '@mui/material'
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded'
import { StatusBubble } from '../../../common/StatusBubble/StatusBubble'
import Table from '../../../../assets/icons/Table.svg'
import EmailDark from '../../../../assets/icons/EmailDark.svg'
import BlendedDataset from '../../../../assets/icons/BlendedDataset.svg'
import UploadDark from '../../../../assets/icons/UploadDark.svg'
import Database from '../../../../assets/icons/DatabaseBlack.svg'
import './DatasetIndex.css'
import { useNavigate } from 'react-router-dom'
import { formatDate } from '../../../../utils/date'
import { ScoopLoader } from '../../../common/Spinner/ScoopLoader'
import { sources } from '../NewDatasetModal/ConnectDatasource'

const identifyTrue = (transactional) => {
    if (transactional) return 'Transactional'
    return 'Snapshot' // Return this if none are true
}

export const DatasetRow = ({
    dataset,
    setAnchorEl,
    setSelectedInbox,
    id,
    processStatus,
    isRunning,
}) => {
    const navigate = useNavigate()
    const { label, description, tables, incremental, transactional, botVerified } = dataset

    const [icon, setIcon] = React.useState(() => <></>)
    const [loading, setLoading] = useState(false)
    const [progress, setProgress] = useState(null)

    useEffect(() => {
        const status = processStatus.get(dataset.inboxID)
        if (status) {
            setLoading(true)
            console.log(status)
            if (status.curStep === -1 || (status.totalSteps === 1 && status.numRecords > 0)) {
                if (status.numRecords > 0) {
                    if (status.totalRecords > 0) {
                        let val = (status.numRecords * 100) / status.totalRecords
                        setProgress(status.curStep === -1 ? 'Starting' : `${val.toFixed(1)}%`)
                    } else {
                        setProgress(status.numRecords.toLocaleString())
                    }
                } else {
                    setProgress('Starting')
                }
            } else if (status.curStep >= 0) {
                let val = status.totalSteps > 0 ? (status.curStep * 100) / status.totalSteps : 0
                setProgress(status.curStep === -1 ? 'Starting' : `${val.toFixed(1)}%`)
            }
        } else {
            setLoading(false)
        }
    }, [processStatus])

    const getIcon = () => {
        switch (dataset.inboxType) {
            case 'email':
                return EmailDark
            case 'robot':
                if (dataset.botService) return Database
                return UploadDark
            case 'calculated':
                return BlendedDataset
            case 'api':
                return (
                    sources.find(
                        (source) => source.sourceValue === dataset.extractDefinition.connectorType
                    ).icon || Database
                )
            default:
                return Database
        }
    }

    const lastIngestion =
        dataset.tables.length > 0
            ? dataset.tables.reduce((latest, current) => {
                  return new Date(current.lastIngestion) > new Date(latest.lastIngestion)
                      ? current
                      : latest
              }).lastIngestion
            : null

    return (
        <ListItem
            key={id}
            className={`dataset-row ${loading ? 'dataset-row-disabled' : ''}`}
            onClick={(e) => navigate(`${dataset.inboxID}`)}
        >
            <Icon
                sx={{
                    height: '28px',
                    width: '28px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'hidden',
                }}
            >
                {<img className={'dataset-icon'} style={{}} src={getIcon()} alt={'dataset-icon'} />}
            </Icon>
            <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
                width={'40%'}
                gap={'8px'}
            >
                <ListItemText
                    primary={label}
                    secondary={description}
                    sx={{
                        whiteSpace: 'nowrap',
                        minWidth: '250px',
                        maxWidth: '250px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        '& .MuiTypography-root': {
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        },
                    }}
                />
                <Box
                    sx={{
                        width: '20%',
                        display: 'flex',
                        alignItems: 'flex-start',
                    }}
                >
                    <Chip
                        label={identifyTrue(transactional)}
                        size="small"
                        sx={{ borderRadius: '5px', background: '#F9F9F9' }}
                    />
                </Box>
                <Chip
                    size="small"
                    label={
                        <Box
                            display={'flex'}
                            alignItems={'center'}
                            gap={'4px'}
                            justifyContent={'space-between'}
                        >
                            <img src={Table} alt={'table'} />
                            {tables.length}
                        </Box>
                    }
                    sx={{ borderRadius: '5px', background: '#F9F9F9' }}
                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    width: '50%',
                    justifyContent: 'flex-end',
                }}
            >
                {loading ? (
                    <>
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                gap: '16px',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                            }}
                        >
                            <Typography className={'last-scoop-date'}>
                                Progress {progress}
                            </Typography>
                            <CircularProgress
                                sx={{
                                    height: '24px !important',
                                    width: '24px !important',
                                    '& .MuiCircularProgress-circle': {
                                        color: '#E50B54',
                                        strokeLinecap: 'round',
                                    },
                                }}
                                variant={'determinate'}
                                value={progress !== -1 ? progress : 0}
                            />
                        </Box>
                    </>
                ) : (
                    <>
                        <Typography className={'last-scoop-date'}>
                            {formatDate(lastIngestion)
                                ? `Last Scoop ${formatDate(lastIngestion)}`
                                : ''}
                        </Typography>
                        <Tooltip title="Last Scoop Status">
                            <StatusBubble status={'success'} />
                        </Tooltip>
                    </>
                )}
            </Box>
            {!dataset.readOnly && isRunning ? (
                <ScoopLoader />
            ) : (
                <IconButton
                    sx={{
                        padding: '4px',
                        borderRadius: '5px',
                        color: 'inherit',
                    }}
                    onClick={(event) => {
                        event.stopPropagation()
                        setAnchorEl(event.currentTarget)
                        setSelectedInbox(dataset)
                    }}
                >
                    <MoreHorizRoundedIcon fontSize={'small'} />
                </IconButton>
            )}
        </ListItem>
    )
}
