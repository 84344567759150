import { FormControl, TextField } from '@mui/material'
import * as React from 'react'
import './Input.css'

const Input = ({
    className,
    label,
    padding,
    onBlur,
    disableMargin,
    containerSx,
    textFieldSx,
    ...props
}) => {
    const [isFocused, setIsFocused] = React.useState(false)

    const handleFocus = () => {
        setIsFocused(true)
    }

    const handleBlur = () => {
        setIsFocused(false)
        if (onBlur) onBlur()
    }

    return (
        <FormControl
            sx={{
                ...containerSx,
                '&.MuiFormControl-root': { marginTop: disableMargin ? '0px !important' : '' },
            }}
            className={'input-container'}
        >
            <label className={'input-label'}>{label}</label>
            <TextField
                {...props}
                className={`input ${className}  ${isFocused ? 'purple-border-input' : ''}`}
                onFocus={handleFocus}
                onBlur={handleBlur}
                sx={{
                    width: '100%',
                    '& .MuiInputBase-input': {
                        padding: padding ? padding : '4px',
                    },
                    '&.MuiFormControl-root': {
                        marginTop: disableMargin && '0px !important',
                    },
                    ...textFieldSx,
                }}
            />
        </FormControl>
    )
}
export default Input
