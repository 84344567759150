// index.js is the entry point for the application.
import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { Amplify } from 'aws-amplify'
import App from './App'
import { Provider } from 'react-redux'
import { store } from './store/store'
import { CssBaseline } from '@mui/material'
import { QueryClientProvider } from '@tanstack/react-query'
import { queryClient } from './api/queryClient'

const baseUrl = `${window.location.protocol}//${window.location.host}`

// Function to determine if the current flow is for Gmail access
const isGmailAccessFlow = () => {
    const queryParams = new URLSearchParams(window.location.search)
    const scope = queryParams.get('scope')
    return scope !== null
}

// Function to determine if the current flow is for API Oauth access
const isAPIOauthAccessFlow = () => {
    const queryParams = new URLSearchParams(window.location.search)
    const connector = queryParams.get('connector')
    let code = queryParams.get('code')
    return code !== null && connector != null && window.location.href.indexOf('googlelogin') == -1
}

// Conditional Amplify configuration
const amplifyConfig = {
    Auth: {
        mandatorySignIn: true,
        region: 'us-west-2',
        userPoolId: 'us-west-2_4RyltqyJ7',
        identityPoolId: 'us-west-2:cc279e26-fc27-4b69-ab62-b4f5817d615b',
        userPoolWebClientId: '76atr62rn4iuk8uhj8r8gt39ms',
    },
}

if (!isGmailAccessFlow() && !isAPIOauthAccessFlow()) {
    amplifyConfig.Auth.oauth = {
        domain: 'auth.scoop-analytics.com',
        scope: ['openid', 'email', 'profile'],
        redirectSignIn: baseUrl + '/googlelogin',
        redirectSignOut: baseUrl + '/logout',
        responseType: 'code',
        identityProviders: ['Google'],
    }
}

Amplify.configure(amplifyConfig)

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
    <QueryClientProvider client={queryClient}>
        <Provider store={store}>
            <CssBaseline />
            <App />
        </Provider>
    </QueryClientProvider>
)

reportWebVitals()
