import { Box, IconButton, MenuItem, Popover, Typography } from '@mui/material'
import DotsThree from '../../../../assets/icons/DotsThree.svg'
import Pencil from '../../../../assets/icons/Pencil.svg'
import Trash from '../../../../assets/icons/TrashRed.svg'
import Bookmark from '../../../../assets/icons/Bookmark.svg'
import { getStringForFilter } from '../../../Insights/Filter'
import { useMemo, useRef, useState } from 'react'
import Dialog from '../../../common/Dialog/Dialog'

const OperatorMap = {
    Equals: 'is one of',
    NotEquals: 'is not one of',
    GreaterThan: 'is greater than',
    LessThan: 'is less than',
    GreaterThanOrEquals: 'is greater than or equal to',
    LessThanOrEquals: 'is less than or equal to',
    Like: 'contains',
    NotLike: 'does not contain',
}

const MENU_OPTIONS = [
    {
        label: 'Edit',
        action: 'edit',
        icon: Pencil,
        color: '#412747',
    },
    {
        label: 'Create Saved Filter',
        action: 'save',
        icon: Bookmark,
        color: '#412747',
    },
    {
        label: 'Remove',
        action: 'remove',
        icon: Trash,
        color: '#D41C0F',
    },
]

export const FilterBox = ({ filter, number, handleRemove, handleEdit, handleSave }) => {
    const anchorEl = useRef(null)
    const [open, setOpen] = useState(false)
    const [isSavingFilter, setIsSavingFilter] = useState(false)

    const filterOperationString = useMemo(() => {
        if (!filter.filterValue) return null
        return filter.attributeName + ' ' + OperatorMap[filter.operator] + ' '
    }, [filter])
    const filterOptionsString = useMemo(() => {
        if (!filter.filterValue) return null
        return filter.filterValue.values
            .map((value, index) => (index > 0 ? `, ${value}` : value))
            .join('')
    }, [filter])

    const handleMenuClick = (action) => {
        switch (action) {
            case 'edit':
                handleEdit()
                break
            case 'save':
                handleSave()
                break
            case 'remove':
                handleRemove()
                break
        }
        setOpen(false)
    }

    return (
        <>
            <Box sx={{ border: '1px solid #F2F2F2', borderRadius: '8px' }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        backgroundColor: '#F2F2F2',
                        borderTopLeftRadius: '8px',
                        borderTopRightRadius: '8px',
                        padding: '4px 12px',
                    }}
                    ref={anchorEl}
                >
                    <Typography sx={{ fontSize: '12px', color: '#2B1630', fontWeight: 600 }}>
                        {number}. {filterOperationString}
                    </Typography>
                    <IconButton onClick={() => setOpen(true)} size={'small'}>
                        <img src={DotsThree} alt={'Three Dots'} />
                    </IconButton>
                </Box>
                <Box sx={{ padding: '8px 12px' }}>
                    <Typography sx={{ fontSize: '14px', color: '#979099', fontWeight: 400 }}>
                        {filterOptionsString}
                    </Typography>
                </Box>
            </Box>
            <Popover
                open={open}
                anchorEl={anchorEl.current}
                onClose={() => setOpen(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                sx={{
                    '& .MuiPaper-root': {
                        borderRadius: '5px',
                    },
                }}
            >
                {MENU_OPTIONS.map((option, index) => (
                    <MenuItem
                        sx={{ alignItems: 'center', gap: '8px' }}
                        key={index}
                        onClick={() => handleMenuClick(option.action)}
                    >
                        <img
                            style={{ width: '22px', height: 'auto' }}
                            src={option.icon}
                            alt={option.label}
                        />
                        <Typography sx={{ fontSize: '14px', color: option.color, fontWeight: 400 }}>
                            {option.label}
                        </Typography>
                    </MenuItem>
                ))}
            </Popover>
        </>
    )
}
