import { mobileAPIRequest } from '../../api'
import userflow from 'userflow.js'

const checkIfRedirect = (createDate) => {
    const fiveMinutesInMilliseconds = 5 * 60 * 1000
    const now = Date.now()

    const sfOffsetMilliseconds = -8 * 60 * 60 * 1000
    const createDateUTC = createDate - sfOffsetMilliseconds

    if (now - createDateUTC < fiveMinutesInMilliseconds) {
        if (!localStorage.getItem('redirectTour')) localStorage.setItem('redirectTour', 'true')
        if (!localStorage.getItem('personalWorkspaceOnboarding'))
            localStorage.setItem('personalWorkspaceOnboarding', 'true')
        if (!localStorage.getItem('onboardingChecklist')) {
            try {
                userflow.track('persona_workspace_onboarding')
                // console.log('sent userflow event', 'persona_workspace_onboarding')
            } catch (error) {}
        }
        // GJ Jan 23: Commented out because UserFlow is supposed to take over the onboarding checklist
        // if (!localStorage.getItem('onboardingChecklist')) dispatch(setOnboardingChecklist('0'))
    }
}

export const getUserInfo = async (userID, dispatch) => {
    const action = {
        action: 'getObjects',
        attributeName: 'userID',
        attributeValue: userID,
        class: 'scoop.user.User',
    }
    const result = await mobileAPIRequest({
        method: 'post',
        data: action,
    })
    if (result && Array.isArray(result.objects)) {
        checkIfRedirect(result.objects[0].createDate, dispatch)
        if (localStorage.getItem('userFirstName') === 'undefined')
            localStorage.setItem('userFirstName', result.objects[0].firstName)
        return result.objects[0]
    }
}
