import {
    deleteEventLog,
    deleteTable,
    getEventLogItems,
    getIngestionMetadata,
    getProcessStatus,
    getReportSeriesMetadata,
    getReportStatus,
    getTablePreviewData,
    renameTable,
} from './datasourceApi'
import { useMutation, useQuery } from '@tanstack/react-query'
import { getWorkspaceID } from '../../../utils'
import { useState } from 'react'

export const MAX_POLLING_ATTEMPTS = 40

export const useReportSeriesMetadata = (reportSeriesTableID) => {
    const {
        data: reportSeriesMetadata,
        isLoading,
        refetch,
    } = useQuery({
        queryKey: ['reportSeriesMetadata', reportSeriesTableID],
        queryFn: () => getReportSeriesMetadata(reportSeriesTableID),
        enabled: !!reportSeriesTableID,
    })

    const { mutate: deleteReportSeries } = useMutation({
        mutationFn: ({ reportSeriesTableID }) => deleteTable(reportSeriesTableID),
        onSuccess: (data, variables) => {
            if (variables.onSuccess) {
                variables.onSuccess()
            }
        },
    })

    const { mutate: renameReportSeries, isPending: isRenameLoading } = useMutation({
        mutationFn: ({ newName, ignoreTable }) =>
            renameTable(reportSeriesTableID, newName, ignoreTable),
        onSuccess: (data, variables) => {
            if (variables.onSuccess) {
                variables.onSuccess()
            }
        },
    })

    return {
        reportSeriesMetadata,
        isLoading,
        refetch,
        deleteReportSeries,
        renameReportSeries,
        isRenameLoading,
    }
}

export const useTablePreviewData = (reportSeriesTableID, latestLoad, enabled) => {
    const {
        data: tablePreviewData,
        isLoading: isLoadingPreview,
        refetch: getPreviewData,
    } = useQuery({
        queryKey: ['tablePreviewData', reportSeriesTableID, latestLoad],
        queryFn: () => getTablePreviewData(reportSeriesTableID, latestLoad),
        enabled: !!reportSeriesTableID && enabled,
    })

    return {
        tablePreviewData,
        isLoadingPreview,
        getPreviewData,
    }
}

export const useIngestionMetadata = (inboxID) => {
    const [pollingAttempt, setPollingAttempt] = useState(0)

    const {
        data: ingestionMetadata,
        isLoading,
        refetch,
    } = useQuery({
        queryKey: ['ingestionMetadata', inboxID],
        queryFn: () => getIngestionMetadata(inboxID),
        enabled: !!inboxID,
    })

    const { data: reportSeriesProcess, isLoading: isProcessLoading } = useQuery({
        queryKey: ['reportSeriesProcess', inboxID],
        queryFn: async () => {
            const result = await getReportStatus(inboxID)
            if (
                !result?.objects?.[0]?.metadata?.initialized &&
                pollingAttempt < MAX_POLLING_ATTEMPTS
            ) {
                setPollingAttempt((prev) => prev + 1)
            }
            return result
        },
        refetchInterval: pollingAttempt < MAX_POLLING_ATTEMPTS ? 5000 : false,
    })

    return {
        ingestionMetadata: ingestionMetadata || {},
        isLoading,
        refetch,
        reportSeriesProcess,
        pollingAttempt,
        isProcessLoading,
    }
}

export const useEventLogItems = (inboxID) => {
    const {
        data: eventLogItems,
        isLoading,
        refetch,
        isGetSuccess,
    } = useQuery({
        queryKey: ['eventLogItems', inboxID],
        queryFn: () => getEventLogItems(inboxID),
        enabled: !!inboxID,
    })

    const { mutate: deleteEventLogItem, isPending: isDeleteLoading } = useMutation({
        mutationFn: ({ deleteLog }) => deleteEventLog(inboxID, deleteLog),
        onSuccess: (data, variables) => {
            refetch().then((response) => {
                if (variables.onSuccess) {
                    variables.onSuccess(response)
                }
            })
        },
    })

    return {
        eventLogItems,
        isGetSuccess,
        deleteEventLogItem,
        isLoading,
        refetch,
        isDeleteLoading,
    }
}

export const useGetProcessStatus = () => {
    const workspaceID = getWorkspaceID()

    const {
        data: processStatus,
        isLoading,
        refetch,
    } = useQuery({
        queryKey: ['processStatus', workspaceID],
        queryFn: () => getProcessStatus(),
        enabled: !!workspaceID,
    })

    return {
        processStatus,
        isLoading,
        refetch,
    }
}
