import Dialog from '../common/Dialog/Dialog'
import { Box, checkboxClasses, IconButton, ListItem, Skeleton } from '@mui/material'
import Button from '../common/Button/Button'
import * as React from 'react'
import Selector from '../common/Selector/Selector'
import Typography from '@mui/material/Typography'
import SearchBar from '../common/SeacrhBar/SearchBar'
import { useWorkspaceMetadata } from '../../api/hooks/workspaceMetadata/useWorkspaceMetadata'
import _, { cloneDeep, debounce } from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'
import MenuItem from '@mui/material/MenuItem'
import { useCategoryValues } from '../../api/hooks/categoryValues/useCategoryValues'
import Checkbox from '../common/Checkbox/Checkbox'
import InfiniteScroll from 'react-infinite-scroll-component'
import { ScoopLoader } from '../common/Spinner/ScoopLoader'
import TrashRed from '../../assets/icons/TrashRed.svg'
import Input from '../common/Input/Input'
import { packFilter, unpackFilters } from './Filter'
import { ScoopDatePicker } from '../common/DatePicker/ScoopDatePicker'
import dayjs from 'dayjs'

export const AddFilterModal = ({
    config,
    setConfig,
    chartProperties,
    analyzeChanges,
    open,
    close,
    saveFilter,
    editState,
}) => {
    const [filterBy, setFilterBy] = useState(null)
    const [operator, setOperator] = useState('Equals')
    const [searchValue, setSearchValue] = useState('')
    const [selectedItems, setSelectedItems] = useState([])
    const [likeValues, setLikeValues] = useState([])
    const [selectedDate, setSelectedDate] = useState(null)
    const [debouncedSearchValue, setDebouncedSearchValue] = useState('')
    const [renderedCategoryValues, setRenderedCategoryValues] = useState([])
    const [savedFilterChecked, setSavedFilterChecked] = useState(false)
    const [savedFilterName, setSavedFilterName] = useState('')
    const scrollableTarget = useRef()

    const isNumeric = filterBy && ['Decimal', 'Currency', 'Integer'].includes(filterBy.columnType)
    const isDate = filterBy && filterBy.columnType === 'DateTime'
    const isLike = ['Like', 'NotLike'].includes(operator)
    const isEditing = !!editState

    const { workspaceMetadata } = useWorkspaceMetadata()
    const { data: categoryValues, isLoading: categoryValuesLoading } = useCategoryValues(
        filterBy?.columnName,
        debouncedSearchValue,
        filterBy?.reportSeriesTableID,
        !isNumeric && !isDate && !isLike
    )

    const debounceSearch = useCallback(
        debounce((v) => setDebouncedSearchValue(v), 300),
        []
    )

    useEffect(() => {
        if (editState) {
            setOperator(editState.operator)
            setSelectedItems(editState.filterValue?.values)
            if (editState.operator === 'Like' || editState.operator === 'NotLike') {
                setLikeValues(editState.filterValue.values)
            }
            handleFilterByChange(editState.attributeName, editState.filterValue?.values)
        }
    }, [])

    useEffect(() => {
        if (categoryValues.length > 0) setRenderedCategoryValues(categoryValues.slice(0, 50))
    }, [categoryValues])

    const handleSetOperator = (operator) => {
        setSelectedItems([])
        setSearchValue('')
        setDebouncedSearchValue('')
        setLikeValues([])
        setSelectedDate(null)
        setOperator(operator)
    }

    const getFilterByOptions = () => {
        const filterOptions = []
        if (config.worksheetID && config.rangeName) {
            const cols =
                chartProperties.drillAttributes?.map((item) => ({
                    value: item,
                    label: item,
                })) || []
            filterOptions.push(...cols)
        } else {
            const tables = []
            workspaceMetadata?.inboxes?.forEach((inbox) => {
                inbox.tables.forEach((table) => {
                    if (config.view === 'table') {
                        if (config.selectedTables.includes(table.reportSeriesTableID)) {
                            tables.push(table)
                        }
                    } else {
                        if (
                            config.selectedItems.some(
                                (i) => i.reportSeriesTableID === table.reportSeriesTableID
                            )
                        ) {
                            tables.push(table)
                        }
                    }
                })
            })
            const columns = tables.flatMap((table) =>
                table.columns.map((col) => ({
                    ...col,
                    reportSeriesTableID: table.reportSeriesTableID,
                }))
            )
            const uniqueColumns = _.uniqBy(columns, (c) => c.columnName).map((col) => ({
                value: col.columnName,
                label: col.columnName,
            }))
            filterOptions.push(...uniqueColumns)
        }
        return filterOptions
    }

    const getFilterOperators = () => {
        const options = [
            { value: 'Equals', label: 'Equals' },
            { value: 'NotEquals', label: 'Not Equals' },
        ]

        if (isNumeric || isDate) {
            options.push(
                { value: 'GreaterThan', label: 'Greater Than' },
                { value: 'GreaterThanOrEquals', label: 'Greater Than or Equals' },
                { value: 'LessThan', label: 'Less Than' },
                { value: 'LessThanOrEquals', label: 'Less Than or Equals' },
                { value: 'Between', label: 'Between' }
            )
        }

        if (!isNumeric && !isDate) {
            options.push({ value: 'Like', label: 'Like' }, { value: 'NotLike', label: 'Not Like' })
        }

        return options
    }

    const handleFilterByChange = (attribute, filterValue) => {
        const tableIds = config.selectedItems.map((i) => i.reportSeriesTableID)
        workspaceMetadata?.inboxes?.forEach((inbox) => {
            inbox.tables.forEach((table) => {
                if (tableIds.includes(table.reportSeriesTableID)) {
                    const column = table.columns.find((col) => col.columnName === attribute)
                    if (column) {
                        setFilterBy({
                            ...column,
                            reportSeriesTableID: table.reportSeriesTableID,
                        })
                        if (column?.columnType === 'DateTime' && filterValue) {
                            const parsedDates = filterValue.map((date) => {
                                const [year, month, day] = date.split('-').map(Number)
                                const parsedDate = new Date()
                                parsedDate.setFullYear(year, month - 1, day)
                                parsedDate.setHours(0, 0, 0, 0)
                                return parsedDate
                            })
                            parsedDates.length > 1
                                ? setSelectedDate(parsedDates)
                                : setSelectedDate(parsedDates[0])
                        }
                    }
                }
            })
        })
    }

    const handleSelectDate = (newDate) => {
        let newSelectedItems = []
        if (operator === 'Between') {
            const from = dayjs(newDate[0]).format('YYYY-MM-DD')
            const to = dayjs(newDate[1]).format('YYYY-MM-DD')
            newSelectedItems.push(from, to)
        } else {
            newSelectedItems.push(dayjs(newDate).format('YYYY-MM-DD'))
        }
        setSelectedItems(newSelectedItems)
        setSelectedDate(newDate)
    }

    const handleNumericChange = (value, position) => {
        let newSelectedItems = cloneDeep(selectedItems)
        if (position === 'from') newSelectedItems[0] = value
        else if (position === 'to') newSelectedItems[1] = value
        else newSelectedItems[0] = value
        setSelectedItems(newSelectedItems)
    }

    const handleNextScroll = () => {
        const currentLength = renderedCategoryValues.length
        setRenderedCategoryValues(categoryValues.slice(0, currentLength + 50))
    }

    const handleSearchChange = (e) => {
        debounceSearch(e.target.value)
        setSearchValue(e.target.value)
    }

    const handleCheckSelectedItems = (value) => {
        if (selectedItems.includes(value)) {
            setSelectedItems(selectedItems.filter((i) => i !== value))
        } else {
            setSelectedItems([...selectedItems, value])
        }
    }

    const addLikeValue = () => {
        if (!searchValue) return
        setLikeValues([...likeValues, searchValue])
        setSelectedItems([...selectedItems, searchValue])
        setSearchValue('')
    }

    const removeLikeValue = (value) => {
        setLikeValues(likeValues.filter((v) => v !== value))
        setSelectedItems(selectedItems.filter((i) => i !== value))
    }

    const parseValues = (values) => {
        if (isNumeric) {
            return values.map((i) => Number(i))
        } else {
            return values
        }
    }

    const handleAddFilter = async (e) => {
        e.stopPropagation()

        let attributeFilter = null
        let index = 0
        let filterList = unpackFilters(chartProperties.config.filter)

        for (let f of filterList) {
            if (f.attributeName === filterBy.columnName && f.operator === operator) {
                attributeFilter = f
                break
            }
            index++
        }
        if (selectedItems.length === 0) {
            if (attributeFilter !== null) {
                filterList.splice(index, 1)
            }
        } else {
            if (isEditing) {
                let existingFilterIndex = filterList.findIndex(
                    (f) =>
                        f.attributeName === editState.attributeName &&
                        f.operator === editState.operator
                )
                if (existingFilterIndex !== -1) {
                    filterList[existingFilterIndex].attributeName = filterBy.columnName
                    filterList[existingFilterIndex].operator = operator
                    filterList[existingFilterIndex].filterValue = {
                        values: parseValues(selectedItems),
                    }
                }
            } else {
                if (attributeFilter == null) {
                    if (operator === 'Between') {
                        let newFilter = {
                            attributeName: filterBy.columnName,
                            operator: 'GreaterThanOrEquals',
                            filterValue: {
                                values: parseValues([selectedItems[0]]),
                            },
                        }
                        filterList.push(newFilter)
                        newFilter = {
                            attributeName: filterBy.columnName,
                            operator: 'LessThanOrEquals',
                            filterValue: {
                                values: parseValues([selectedItems[1]]),
                            },
                        }
                        filterList.push(newFilter)
                    } else {
                        attributeFilter = {
                            attributeName: filterBy.columnName,
                            operator: operator,
                            filterValue: {
                                values: parseValues(selectedItems),
                            },
                        }
                        filterList.push(attributeFilter)
                    }
                } else {
                    attributeFilter.operator = operator
                    attributeFilter.filterValue = {
                        values: parseValues(selectedItems),
                    }
                }
            }
        }
        let newFilter = packFilter(filterList)
        config.filter = newFilter
        chartProperties.config.filter = newFilter
        setConfig({ ...config })
        chartProperties.getResults(config)
        if (savedFilterChecked) await saveFilter(savedFilterName, newFilter)
        close()
    }

    const renderValueSection = () => {
        if (isNumeric) {
            if (operator === 'Between') {
                return (
                    <>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: '10px',
                                mb: '10px',
                            }}
                        >
                            <Input
                                label={'From'}
                                type={'number'}
                                value={selectedItems[0]}
                                onChange={(e) => handleNumericChange(e.target.value, 'from')}
                            />
                            <Input
                                label={'To'}
                                type={'number'}
                                value={selectedItems[1]}
                                onChange={(e) => handleNumericChange(e.target.value, 'to')}
                            />
                        </Box>
                        <Typography className={'inter'} sx={{ color: '#979099' }}>
                            Endpoints are included
                        </Typography>
                    </>
                )
            } else {
                return (
                    <Input
                        label={'Value'}
                        type={'number'}
                        value={selectedItems[0]}
                        onChange={(e) => handleNumericChange(e.target.value)}
                    />
                )
            }
        } else if (isDate) {
            return (
                <ScoopDatePicker
                    value={selectedDate}
                    onChange={(newValue) => handleSelectDate(newValue)}
                    label={`Select ${operator === 'Between' ? 'range' : 'date'}`}
                    range={operator === 'Between'}
                />
            )
        } else {
            if (isLike) {
                return (
                    <>
                        <Box
                            display="flex"
                            flexDirection="column"
                            gap="6px"
                            sx={{ maxHeight: '269px', overflow: 'auto' }}
                        >
                            {likeValues.map((value) => (
                                <Box
                                    key={value}
                                    display="flex"
                                    gap="8px"
                                    alignItems="center"
                                    justifyContent={'space-between'}
                                >
                                    <Typography sx={{ color: '#14092A', fontSize: '14px' }}>
                                        {value}
                                    </Typography>
                                    <IconButton onClick={() => removeLikeValue(value)}>
                                        <img
                                            src={TrashRed}
                                            alt={'Trash Icon'}
                                            style={{ height: 20 }}
                                        />
                                    </IconButton>
                                </Box>
                            ))}
                        </Box>
                    </>
                )
            } else {
                if (categoryValuesLoading) {
                    return (
                        <Box
                            ref={scrollableTarget}
                            display="flex"
                            flexDirection="column"
                            gap="6px"
                            sx={{ maxHeight: '269px', overflow: 'auto' }}
                        >
                            {[...Array(10)].map((_, i) => (
                                <Skeleton key={i} height={36} />
                            ))}
                        </Box>
                    )
                } else {
                    if (categoryValues.length > 0) {
                        return (
                            <>
                                <Box
                                    ref={scrollableTarget}
                                    display="flex"
                                    flexDirection="column"
                                    gap="6px"
                                    sx={{ maxHeight: '269px', overflow: 'auto' }}
                                >
                                    <InfiniteScroll
                                        scrollableTarget={scrollableTarget.current}
                                        dataLength={renderedCategoryValues.length}
                                        next={handleNextScroll}
                                        hasMore={
                                            renderedCategoryValues.length < categoryValues.length
                                        }
                                        loader={<ScoopLoader />}
                                    >
                                        {renderedCategoryValues.map((value) => (
                                            <Box
                                                key={value}
                                                display="flex"
                                                gap="8px"
                                                alignItems="center"
                                            >
                                                <Checkbox
                                                    checked={selectedItems.includes(value)}
                                                    onClick={() => handleCheckSelectedItems(value)}
                                                    size={'medium'}
                                                />
                                                <Typography
                                                    sx={{ color: '#14092A', fontSize: '14px' }}
                                                >
                                                    {value}
                                                </Typography>
                                            </Box>
                                        ))}
                                    </InfiniteScroll>
                                </Box>
                            </>
                        )
                    } else {
                        return (
                            <Typography className={'inter'} sx={{ color: '#979099' }}>
                                No values found for current search criteria
                            </Typography>
                        )
                    }
                }
            }
        }
    }

    return (
        <Dialog
            open={open}
            onClose={close}
            title={isEditing ? 'Edit filter' : 'Add new filter'}
            actions={
                <Box display="flex" justifyContent="flex-end" width="100% " gap="8px">
                    <Button className={'button-grey small'} onClick={close} text={'Cancel'} />
                    <Button
                        disabled={!filterBy || !operator}
                        onClick={handleAddFilter}
                        className={'button-purple small'}
                    >
                        {isEditing ? 'Save Changes' : 'Add filter'}
                    </Button>
                </Box>
            }
            style={{ width: 600 }}
        >
            <Box display="flex" gap="8px" width="100%">
                <Selector
                    key={filterBy?.columnName}
                    value={filterBy?.columnName}
                    onChange={(e) => handleFilterByChange(e.target.value)}
                    label={'Filter by'}
                    sx={{ height: 36 }}
                    labelClassName={'selector-label-bold'}
                >
                    {getFilterByOptions().map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            <Typography className={'inter'}>{option.label}</Typography>
                        </MenuItem>
                    ))}
                </Selector>
                <Selector
                    value={operator}
                    onChange={(e) => handleSetOperator(e.target.value)}
                    label={'Operator'}
                    sx={{ height: 36 }}
                    labelClassName={'selector-label-bold'}
                >
                    {getFilterOperators().map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            <Typography className={'inter'}>{option.label}</Typography>
                        </MenuItem>
                    ))}
                </Selector>
            </Box>
            <Box display="flex" flexDirection="column" gap="8px">
                <Typography className={'inter selector-label-bold'}>Value</Typography>
                <Box
                    display="flex"
                    flexDirection="column"
                    gap="16px"
                    sx={{
                        borderRadius: '5px',
                        padding: '16px',
                        backgroundColor: '#F9F9F9',
                        minHeight: '360px',
                    }}
                >
                    {!isDate && !isNumeric && (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <SearchBar
                                value={searchValue}
                                onChange={handleSearchChange}
                                placeholder={isLike ? 'Add value to match' : 'Search for a value'}
                                sx={{ width: '100%' }}
                                noIcon={isLike}
                            />
                            {isLike && (
                                <Button
                                    className={'button-purple small'}
                                    sx={{ ml: '10px' }}
                                    onClick={addLikeValue}
                                    disabled={!searchValue}
                                >
                                    Add value
                                </Button>
                            )}
                        </Box>
                    )}
                    {renderValueSection()}
                </Box>
                <Box>
                    {!isEditing && (
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Checkbox
                                checked={savedFilterChecked}
                                onClick={() => setSavedFilterChecked(!savedFilterChecked)}
                                size={'medium'}
                            />
                            <Typography
                                className={'inter'}
                                sx={{ fontSize: '14px', marginLeft: '5px' }}
                            >
                                Save as saved filter and replace current selection
                            </Typography>
                        </Box>
                    )}
                    {savedFilterChecked && (
                        <Box sx={{ mt: 1 }}>
                            <Input
                                placeholder={'Enter saved filter name'}
                                style={{ width: '60%' }}
                                label={'Saved filter name'}
                                value={savedFilterName}
                                onChange={(e) => setSavedFilterName(e.target.value)}
                            />
                        </Box>
                    )}
                </Box>
            </Box>
        </Dialog>
    )
}
