import React, { useCallback, useEffect, useRef, useState } from 'react'
import './FilterCreator.css'
import { Box, Checkbox, checkboxClasses, ListItem, MenuItem, Typography } from '@mui/material'
import Selector from '../common/Selector/Selector'
import { ScoopLoader } from '../common/Spinner/ScoopLoader'
import Button from '../common/Button/Button'
import { packFilter, unpackFilters } from '../Insights/Filter'
import { cloneDeep, debounce } from 'lodash'
import Input from '../common/Input/Input'
import { useCategoryValues } from '../../api/hooks/categoryValues/useCategoryValues'
import { useWorkspaceMetadata } from '../../api/hooks/workspaceMetadata/useWorkspaceMetadata'
import InfiniteScroll from 'react-infinite-scroll-component'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '../../assets/icons/CloseIcon.svg'
import dayjs from 'dayjs'
import { ScoopDatePicker } from '../common/DatePicker/ScoopDatePicker'

export const FilterCreator = ({
    source,
    metricFilters,
    setMetricFilters,
    setFilterCreation,
    metric,
    setMetric,
}) => {
    const [selectedColumn, setSelectedColumn] = useState(null)
    const [operator, setOperator] = useState('Equals')
    const [selectedCategoryValues, setSelectedCategoryValues] = useState([])
    const [saveAsSavedFilter, setSaveAsSavedFilter] = useState(false)
    const [filterName, setFilterName] = useState('')
    const [nameError, setNameError] = useState('')
    const [likeValue, setLikeValue] = useState(null)
    const [likeValues, setLikeValues] = useState([])
    const [debouncedLikeValue, setDebouncedLikeValue] = useState('')
    const [renderedCategoryValues, setRenderedCategoryValues] = useState([])
    const [selectedDate, setSelectedDate] = useState(null)
    const scrollableTarget = useRef()

    const isNumeric =
        selectedColumn &&
        (selectedColumn.columnType === 'Decimal' ||
            selectedColumn.columnType === 'Currency' ||
            selectedColumn.columnType === 'Integer')
    const isDate = selectedColumn && selectedColumn.columnType === 'DateTime'
    const isLike = ['Like', 'NotLike'].includes(operator)

    const { isLoading: categoryValuesLoading, data: categoryValues } = useCategoryValues(
        selectedColumn?.columnName,
        likeValue,
        source.table.reportSeriesTableID,
        !isNumeric && !isDate && !isLike
    )
    const {
        workspaceMetadata,
        saveFilter,
        saveFilterPending: savedFilterLoading,
        isFetching: workspaceMetadataLoading,
    } = useWorkspaceMetadata()
    const checkUsedName = useCallback(
        debounce((v) => {
            if (
                workspaceMetadata.filters.some(
                    (f) => f.filterName.toLowerCase() === v.toLowerCase().trim()
                )
            ) {
                setNameError('Filter name already in use')
            } else {
                if (nameError) setNameError('')
            }
        }, 200),
        [nameError, workspaceMetadata]
    )
    const debounceLikeValueChange = useCallback(
        debounce((v) => {
            if (v) setLikeValue(v)
            else setLikeValue(null)
        }, 300),
        []
    )

    useEffect(() => {
        if (categoryValues.length > 0) {
            setRenderedCategoryValues(categoryValues.slice(0, 50))
        }
    }, [categoryValues])

    const handleSetOperator = (operator) => {
        setSelectedCategoryValues([])
        setLikeValue(null)
        setLikeValues([])
        setDebouncedLikeValue('')
        setSelectedDate(null)
        setOperator(operator)
    }

    const handleCategoryValueToggle = (value) => {
        if (selectedCategoryValues.includes(value)) {
            setSelectedCategoryValues(selectedCategoryValues.filter((v) => v !== value))
        } else {
            setSelectedCategoryValues((prev) => [...prev, value])
        }
    }

    const parseValues = (values) => {
        if (isNumeric) {
            return values.map((i) => Number(i))
        } else {
            return values
        }
    }

    const handleSaveFilter = () => {
        const filterList = saveAsSavedFilter ? [] : unpackFilters(metricFilters)
        if (operator === 'Between') {
            const startFilter = {
                attributeName: selectedColumn.columnName,
                operator: 'GreaterThanOrEquals',
                filterValue: { values: parseValues([selectedCategoryValues[0]]) },
            }
            filterList.push(startFilter)
            const endFilter = {
                attributeName: selectedColumn.columnName,
                operator: 'LessThanOrEquals',
                filterValue: { values: parseValues([selectedCategoryValues[1]]) },
            }
            filterList.push(endFilter)
        } else {
            const attributeFilter = {
                attributeName: selectedColumn.columnName,
                operator: operator,
                filterValue: {
                    values:
                        operator === 'Like' || operator === 'NotLike'
                            ? parseValues(likeValues)
                            : parseValues(selectedCategoryValues),
                },
            }
            filterList.push(attributeFilter)
        }
        const filterToSave = packFilter(filterList)
        if (saveAsSavedFilter) {
            saveFilter({
                filterName: filterName.trim(),
                filter: filterToSave,
                onSuccess: (workspaceMetadata) => {
                    const newFilter = workspaceMetadata.filters.find(
                        (f) => f.filterName === filterName.trim()
                    )
                    const newMetric = cloneDeep(metric)
                    newMetric.savedFilterID = newFilter.savedFilterKey
                    setMetric(newMetric)
                    setFilterCreation(false)
                },
            })
        } else {
            setMetricFilters(filterToSave)
            setFilterCreation(false)
        }
    }

    const handleNameChange = (v) => {
        checkUsedName(v)
        setFilterName(v)
    }

    const handleNextScroll = () => {
        const currentLength = renderedCategoryValues.length
        setRenderedCategoryValues(categoryValues.slice(0, currentLength + 50))
    }

    const handleSearchChange = (v) => {
        setDebouncedLikeValue(v)
        debounceLikeValueChange(v)
    }

    const handleAddLikeValue = () => {
        setLikeValues((state) => [...state, likeValue])
        setLikeValue(null)
        setDebouncedLikeValue('')
    }

    const handleRemoveLikeValue = (value) => {
        setLikeValues(likeValues.filter((v) => v !== value))
    }

    const getFilterOperators = () => {
        const options = [
            { value: 'Equals', label: 'Equals' },
            { value: 'NotEquals', label: 'Not Equals' },
        ]

        if (isNumeric || isDate) {
            options.push(
                { value: 'GreaterThan', label: 'Greater Than' },
                { value: 'GreaterThanOrEquals', label: 'Greater Than or Equals' },
                { value: 'LessThan', label: 'Less Than' },
                { value: 'LessThanOrEquals', label: 'Less Than or Equals' },
                { value: 'Between', label: 'Between' }
            )
        }

        if (!isNumeric && !isDate) {
            options.push({ value: 'Like', label: 'Like' }, { value: 'NotLike', label: 'Not Like' })
        }

        return options
    }

    const handleSelectDate = (newDate) => {
        let newSelectedItems = []
        if (operator === 'Between') {
            const from = dayjs(newDate[0]).format('YYYY-MM-DD')
            const to = dayjs(newDate[1]).format('YYYY-MM-DD')
            newSelectedItems.push(from, to)
        } else {
            newSelectedItems.push(dayjs(newDate).format('YYYY-MM-DD'))
        }
        setSelectedCategoryValues(newSelectedItems)
        setSelectedDate(newDate)
    }

    const handleNumericChange = (value, position) => {
        let newSelectedItems = cloneDeep(selectedCategoryValues)
        if (position === 'from') newSelectedItems[0] = value
        else if (position === 'to') newSelectedItems[1] = value
        else newSelectedItems[0] = value
        setSelectedCategoryValues(newSelectedItems)
    }

    const renderValueSection = () => {
        if (isNumeric) {
            if (operator === 'Between') {
                return (
                    <>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: '10px',
                                mb: '10px',
                            }}
                        >
                            <Input
                                label={'From'}
                                type={'number'}
                                value={selectedCategoryValues[0]}
                                onChange={(e) => handleNumericChange(e.target.value, 'from')}
                            />
                            <Input
                                label={'To'}
                                type={'number'}
                                value={selectedCategoryValues[1]}
                                onChange={(e) => handleNumericChange(e.target.value, 'to')}
                            />
                        </Box>
                        <Typography className={'inter'} sx={{ color: '#979099' }}>
                            Endpoints are included
                        </Typography>
                    </>
                )
            } else {
                return (
                    <Input
                        label={'Value'}
                        type={'number'}
                        value={selectedCategoryValues[0]}
                        onChange={(e) => handleNumericChange(e.target.value)}
                    />
                )
            }
        } else if (isDate) {
            return (
                <ScoopDatePicker
                    value={selectedDate}
                    onChange={(newValue) => handleSelectDate(newValue)}
                    label={`Select ${operator === 'Between' ? 'range' : 'date'}`}
                    range={operator === 'Between'}
                />
            )
        } else {
            if (operator === 'Like' || operator === 'NotLike') {
                return (
                    <>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'flex-end',
                                gap: '10px',
                                mb: '10px',
                            }}
                        >
                            <Input
                                label={'Like value'}
                                value={debouncedLikeValue}
                                onChange={(e) => handleSearchChange(e.target.value)}
                            />
                            <Button
                                disabled={!likeValue}
                                onClick={handleAddLikeValue}
                                className={'primary-button button-purple add-like-value-button'}
                            >
                                <Typography className={'inter'} sx={{ fontSize: '14px' }}>
                                    Add value
                                </Typography>
                            </Button>
                        </Box>
                        {likeValues.length > 0 ? (
                            likeValues.map((value, i) => (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        gap: '10px',
                                        mb: '5px',
                                    }}
                                >
                                    <Typography className={'inter'}>{value}</Typography>
                                    <IconButton onClick={() => handleRemoveLikeValue(value)}>
                                        <img
                                            src={CloseIcon}
                                            alt={'Delete'}
                                            style={{ height: 15 }}
                                        />
                                    </IconButton>
                                </Box>
                            ))
                        ) : (
                            <Typography className={'inter'} sx={{ color: '#979099' }}>
                                Add values to match
                            </Typography>
                        )}
                    </>
                )
            } else {
                if (categoryValuesLoading) {
                    return (
                        <Box className={'filter-creator-loader-container'}>
                            <ScoopLoader size={60} />
                        </Box>
                    )
                } else {
                    if (categoryValues.length > 0) {
                        return (
                            <InfiniteScroll
                                scrollableTarget={scrollableTarget.current}
                                dataLength={renderedCategoryValues.length}
                                next={handleNextScroll}
                                hasMore={renderedCategoryValues.length < categoryValues.length}
                                loader={<ScoopLoader />}
                            >
                                {renderedCategoryValues.map((v) => (
                                    <ListItem sx={{ padding: '2px 2px 2px 0', width: '100%' }}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Checkbox
                                                sx={{
                                                    height: 10,
                                                    width: 10,
                                                    '&.MuiCheckbox-root': {
                                                        color: '#C7C5C7',
                                                    },
                                                    [`&, &.${checkboxClasses.checked}`]: {
                                                        color: '#E50B54',
                                                    },
                                                }}
                                                onClick={() => handleCategoryValueToggle(v)}
                                                checked={selectedCategoryValues.includes(v)}
                                                size={'medium'}
                                            />
                                            <Typography
                                                className={'inter'}
                                                sx={{ fontSize: 14, marginLeft: '5px' }}
                                            >
                                                {v}
                                            </Typography>
                                        </Box>
                                    </ListItem>
                                ))}
                            </InfiniteScroll>
                        )
                    } else {
                        return (
                            <Typography className={'inter'} sx={{ color: '#979099' }}>
                                No values found for current search criteria
                            </Typography>
                        )
                    }
                }
            }
        }
    }

    return (
        <>
            <Box className={'filter-creator-selectors-container'}>
                <Box className={'filter-creator-selector'}>
                    <Typography className={'inter'} sx={{ fontSize: '14px', fontWeight: 600 }}>
                        Filter by
                    </Typography>
                    <Selector
                        value={selectedColumn}
                        onChange={(e) => setSelectedColumn(e.target.value)}
                        sx={{ height: '32px !important' }}
                    >
                        {source?.table?.columns?.map((col) => (
                            <MenuItem className={'inter'} value={col} key={col.columnName}>
                                {col.columnName}
                            </MenuItem>
                        ))}
                    </Selector>
                </Box>
                <Box className={'filter-creator-selector'}>
                    <Typography className={'inter'} sx={{ fontSize: '14px', fontWeight: 600 }}>
                        Filter type
                    </Typography>
                    <Selector
                        value={operator}
                        disabled={!selectedColumn}
                        onChange={(e) => handleSetOperator(e.target.value)}
                        sx={{ height: '32px !important' }}
                    >
                        {getFilterOperators().map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                <Typography className={'inter'}>{option.label}</Typography>
                            </MenuItem>
                        ))}
                    </Selector>
                </Box>
                {!isDate && !isNumeric && operator !== 'Like' && operator !== 'NotLike' && (
                    <Input
                        label={'Search'}
                        value={debouncedLikeValue}
                        disabled={!selectedColumn}
                        onChange={(e) => handleSearchChange(e.target.value)}
                    />
                )}
            </Box>
            <Box
                className={'filter-creator-category-values'}
                sx={{
                    height: `calc(100% - ${saveAsSavedFilter ? (nameError ? 175 : 165) : 110}px)`,
                }}
                ref={scrollableTarget}
            >
                {renderValueSection()}
            </Box>
            {saveAsSavedFilter && (
                <>
                    <Input
                        label={'Filter name'}
                        value={filterName}
                        onChange={(e) => handleNameChange(e.target.value)}
                    />
                    {nameError && (
                        <Typography className={'inter'} sx={{ color: 'red', fontSize: '12px' }}>
                            {nameError}
                        </Typography>
                    )}
                </>
            )}
            <Box
                className={'filter-creator-footer-container'}
                sx={{ paddingTop: nameError ? 0 : '10px' }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Checkbox
                        sx={{
                            height: 10,
                            width: 10,
                            '&.MuiCheckbox-root': {
                                color: '#C7C5C7',
                            },
                            [`&, &.${checkboxClasses.checked}`]: {
                                color: '#E50B54',
                            },
                        }}
                        onClick={() => setSaveAsSavedFilter(!saveAsSavedFilter)}
                        checked={saveAsSavedFilter}
                        size={'medium'}
                    />
                    <Typography className={'inter'} sx={{ fontSize: '14px', marginLeft: '5px' }}>
                        Save as saved filter and replace current selection
                    </Typography>
                </Box>
                <Button
                    disabled={
                        nameError ||
                        savedFilterLoading ||
                        workspaceMetadataLoading ||
                        !selectedColumn ||
                        (operator === 'Like' || operator === 'NotLike'
                            ? likeValues.length === 0
                            : selectedCategoryValues.length === 0 ||
                              selectedCategoryValues.some((v) => !v)) ||
                        (saveAsSavedFilter ? !filterName : false)
                    }
                    onClick={handleSaveFilter}
                    className={'primary-button button-purple save-filter-button'}
                >
                    {savedFilterLoading || workspaceMetadataLoading ? (
                        <ScoopLoader />
                    ) : (
                        <Typography className={'inter'} sx={{ fontSize: '14px' }}>
                            Save filter
                        </Typography>
                    )}
                </Button>
            </Box>
        </>
    )
}
