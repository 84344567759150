import { mobileAPIRequest, workspaceClientRequest } from '../../api'

export const getUserColleagues = async () => {
    const action = {
        action: 'listUserColleagues',
    }
    const response = await workspaceClientRequest({
        method: 'post',
        data: action,
    })
    if (response?.status === 'success') {
        return response.body.map((user) => ({
            label: user.userName,
            value: user.userID,
        }))
    }
    return []
}

export const getUsersFromWorkspace = async (workspaceID) => {
    const action = {
        action: 'readWorkspaceUsers',
        workspaceID,
    }
    const response = await workspaceClientRequest({
        method: 'post',
        data: action,
    })
    if (response?.status === 'success') {
        return response.body
    }
    return []
}

export const addUserToWorkspace = async (workspaceID, userID, role) => {
    const action = {
        action: 'putObject',
        class: 'scoop.workspace.WorkspaceUser',
        value: {
            workspaceID,
            userID,
            role,
        },
    }
    return await mobileAPIRequest({
        method: 'post',
        data: action,
    })
}

export const removeUserFromWorkspace = async (workspaceID, userID) => {
    const action = {
        action: 'deleteObject',
        class: 'scoop.workspace.WorkspaceUser',
        keyParts: [workspaceID, userID],
    }
    return await mobileAPIRequest({
        method: 'post',
        data: action,
    })
}
