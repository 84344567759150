import { Box, DialogContentText, List, ListItemButton, ListItemText } from '@mui/material'
import * as React from 'react'
import Dialog from '../common/Dialog/Dialog'
import Button from '../common/Button/Button'
import { useInsights } from '../../api/hooks/insights/useInsights'
import { useEffect, useState } from 'react'
import { ScoopFileDirectory } from '../common/ScoopFileDirectory/ScoopFileDirectory'
import { files } from '../common/ScoopFileDirectory/FilesMock'

export function OpenDialog({
    open,
    setOpen,
    setInsight,
    workspaceMetadata,
    server,
    setSelectedItemToLoad,
    setAnchor,
    window,
    resetState,
}) {
    const [selectedItem, setSelectedItem] = useState(null)
    const { insights, isLoading, refetch, deleteInsight, isDeleting } = useInsights()

    function handleClose(event) {
        setOpen(false)
        if (setAnchor) setAnchor(null)
    }

    useEffect(() => {
        if (setSelectedItemToLoad) {
            setSelectedItemToLoad(selectedItem)
        }
    }, [selectedItem, setSelectedItemToLoad])

    const handleOpen = () => {
        return server.postData(
            {
                action: 'getObject',
                class: 'scoop.insight.Insight',
                key: selectedItem,
            },
            (result) => {
                setOpen(false)
                if (setAnchor) setAnchor(null)
                resetState && resetState()
                let insight
                insight = JSON.parse(result.savedObject)
                insight.insightKey = result.insightKey
                insight.insightName = result.insightName
                setInsight(insight)
            }
        )
    }

    const handleDelete = () => {
        deleteInsight({
            insightKey: selectedItem,
            onSuccessCallback: () => {
                setInsight(null)
                setOpen(false)
                setSelectedItem(null)
                if (setAnchor) setAnchor(null)
            },
        })
    }

    const actions = (
        <Box display="flex" justifyContent="flex-end" width="100% " gap="8px">
            <Button onClick={handleClose} className={'primary-button small'} text={'Cancel'} />
            {window && (
                <Button
                    onClick={handleDelete}
                    className={'primary-button button-grey small'}
                    text={'Delete'}
                    disabled={!selectedItem}
                />
            )}
            <Button
                name={'open-insight-button'}
                onClick={handleOpen}
                className={'primary-button button-purple small'}
                text={'Open'}
                disabled={!selectedItem}
            />
        </Box>
    )

    const sortedInsights = insights.sort((a, b) => {
        if (a.insightName === null) return 1
        if (b.insightName === null) return -1
        return a.insightName.toLowerCase().localeCompare(b.insightName)
    })

    const files = sortedInsights?.map((insight) => ({
        id: insight.insightKey,
        path: insight.path,
        name: insight.insightName,
    }))

    if (workspaceMetadata) {
        return (
            <Dialog open={open} onClose={handleClose} maxWidth={'800px'} title={'Open summary'}>
                <DialogContentText>Select from summaries saved to Workspace</DialogContentText>
                {/*{sortedInsights && (
                    <Box
                        sx={{
                            height: 400,
                            minWidth: 700,
                            bgcolor: 'background.paper',
                            mt: 2,
                            border: '0.5px solid #E50B54',
                            borderRadius: '5px',
                            padding: 1,
                            overflow: 'scroll',
                        }}
                    >
                        <List dense={true}>
                            {sortedInsights?.map((wi) => {
                                return (
                                    <ListItemButton
                                        selected={selectedItem && selectedItem === wi.insightKey}
                                        key={wi.insightKey}
                                        onClick={(event) => {
                                            setSelectedItem(wi.insightKey)
                                        }}
                                    >
                                        <ListItemText primary={wi.insightName} />
                                    </ListItemButton>
                                )
                            })}
                        </List>
                    </Box>
                )}*/}
                <ScoopFileDirectory
                    files={files}
                    selectedFile={selectedItem}
                    setSelectedFile={setSelectedItem}
                />
                {actions}
            </Dialog>
        )
    } else return null
}
